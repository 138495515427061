var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_c('v-autocomplete-filter',{staticClass:"autocomplete",attrs:{"companies":_vm.companyId,"multiple":"","clearable":""},model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}}),_c('table-overview',{attrs:{"title":"Filter logs","icon":"far fa-fw fa-globe","store":"company/filterLogs","headers":[
      { text: 'Title', value: 'title' },
      { text: 'Description', value: 'description' },
      { text: 'Occurred at', value: 'occurredAt' },
      {
        text: 'Details',
        sortable: false,
        value: 'details',
        align: 'center',
        width: '100px'
      }
    ],"force-initial-sort":"occurred_at","descending":"","show-header":false},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('company-filter-log-table-row',{attrs:{"filter-log-id":item}})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
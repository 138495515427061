<template>
  <v-card
    class="mb-2 pa-3"
    flat
    style="position: relative"
    :class="
      !filter.isActive
        ? 'grey lighten-3'
        : filter.paused
        ? 'warning lighten-3'
        : ''
    "
  >
    <div class="filter-title">
      {{ country.name }} - {{ filter.name }}
      <v-chip v-if="!filter.isActive" class="inactive" label small>
        inactive
      </v-chip>
      <v-chip v-else-if="filter.paused" class="paused" label small>
        paused
      </v-chip>
      <v-chip v-if="isFilterEuroPriceSet" label small>
        € {{ filter.priceEuro }}
      </v-chip>
      <v-chip v-else-if="isFilterCreditPriceSet" label small>
        C {{ filter.priceCredit }}
      </v-chip>
      <v-chip v-if="isFilterApiKeySet" label small class="blue lighten-2">
        API {{ filter.apiKey }}
      </v-chip>
      <span style="float: right">
        {{ filter.currentWeeklyLeads }} /
        {{ filter.maxWeeklyLeads === null ? "&infin;" : filter.maxWeeklyLeads }}
      </span>
      <v-icon v-if="filter.isAutoDelivery">
        fas fa-envelope
      </v-icon>
    </div>
    <v-list-tile-sub-title v-html="categoryNamesString" />
    <company-filters-delivery-method
      v-for="(deliveryMethod, index) in deliveryMethods"
      :key="index"
      :delivery-method="deliveryMethod"
    />
    <v-btn
      icon
      absolute
      right
      style="bottom: 16px"
      :to="{
        name: 'company.filters.show',
        params: { id: companyId, filterId: filterId }
      }"
    >
      <v-icon>edit</v-icon>
    </v-btn>
  </v-card>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { isNil, map } from "lodash";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
import CompanyFiltersDeliveryMethod from "./CompanyFiltersDeliveryMethod";

const { mapGetters: mapFilterGetters } = createNamespacedHelpers(
  "company/companyFilters"
);

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "company-filters-tile",
  components: { CompanyFiltersDeliveryMethod },
  props: {
    companyId: {
      type: Number,
      required: true
    },
    filterId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapFilterGetters([FIND_BY_ID]),
    filter() {
      return this[FIND_BY_ID](this.filterId);
    },
    country() {
      return this.$store.getters["company/companyFilters/country"](
        this.filterId
      );
    },
    categories() {
      return this.$store.getters["company/companyFilters/categories"](
        this.filterId
      );
    },
    deliveryMethods() {
      return this.$store.getters["company/companyFilters/deliveryMethods"](
        this.filterId
      );
    },
    isFilterEuroPriceSet() {
      return !isNil(this.filter.priceEuro);
    },
    isFilterCreditPriceSet() {
      return isNil(this.filter.priceEuro) && !isNil(this.filter.priceCredit);
    },
    isFilterApiKeySet() {
      return !isNil(this.filter.apiKey);
    },
    categoryNamesString() {
      return map(this.categories, "name.nl_be").join(", ");
    }
  }
};
</script>
<style scoped>
.filter-title {
  font-size: 20px;
}

.paused {
  background-color: orange;
}

.inactive {
  background-color: #212121;
  color: white;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-2 pa-3",class:!_vm.filter.isActive
      ? 'grey lighten-3'
      : _vm.filter.paused
      ? 'warning lighten-3'
      : '',staticStyle:{"position":"relative"},attrs:{"flat":""}},[_c('div',{staticClass:"filter-title"},[_vm._v(" "+_vm._s(_vm.country.name)+" - "+_vm._s(_vm.filter.name)+" "),(!_vm.filter.isActive)?_c('v-chip',{staticClass:"inactive",attrs:{"label":"","small":""}},[_vm._v(" inactive ")]):(_vm.filter.paused)?_c('v-chip',{staticClass:"paused",attrs:{"label":"","small":""}},[_vm._v(" paused ")]):_vm._e(),(_vm.isFilterEuroPriceSet)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" € "+_vm._s(_vm.filter.priceEuro)+" ")]):(_vm.isFilterCreditPriceSet)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" C "+_vm._s(_vm.filter.priceCredit)+" ")]):_vm._e(),(_vm.isFilterApiKeySet)?_c('v-chip',{staticClass:"blue lighten-2",attrs:{"label":"","small":""}},[_vm._v(" API "+_vm._s(_vm.filter.apiKey)+" ")]):_vm._e(),_c('span',{staticStyle:{"float":"right"}},[_vm._v(" "+_vm._s(_vm.filter.currentWeeklyLeads)+" / "+_vm._s(_vm.filter.maxWeeklyLeads === null ? "∞" : _vm.filter.maxWeeklyLeads)+" ")]),(_vm.filter.isAutoDelivery)?_c('v-icon',[_vm._v(" fas fa-envelope ")]):_vm._e()],1),_c('v-list-tile-sub-title',{domProps:{"innerHTML":_vm._s(_vm.categoryNamesString)}}),_vm._l((_vm.deliveryMethods),function(deliveryMethod,index){return _c('company-filters-delivery-method',{key:index,attrs:{"delivery-method":deliveryMethod}})}),_c('v-btn',{staticStyle:{"bottom":"16px"},attrs:{"icon":"","absolute":"","right":"","to":{
      name: 'company.filters.show',
      params: { id: _vm.companyId, filterId: _vm.filterId }
    }}},[_c('v-icon',[_vm._v("edit")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="pa-5">
    <v-autocomplete-filter
      v-model="selectedFilters"
      :companies="companyId"
      multiple
      clearable
      class="autocomplete"
    />
    <table-overview
      title="Filter logs"
      icon="far fa-fw fa-globe"
      store="company/filterLogs"
      :headers="[
        { text: 'Title', value: 'title' },
        { text: 'Description', value: 'description' },
        { text: 'Occurred at', value: 'occurredAt' },
        {
          text: 'Details',
          sortable: false,
          value: 'details',
          align: 'center',
          width: '100px'
        }
      ]"
      force-initial-sort="occurred_at"
      descending
      :show-header="false"
    >
      <company-filter-log-table-row
        slot="table-row"
        slot-scope="{ item }"
        :filter-log-id="item"
      />
    </table-overview>
  </div>
</template>
<script type="text/babel">
import CompanyFilterLogTableRow from "@/components/company/filter-logs/CompanyFilterLogTableRow";
import TableOverview from "@/components/generic/overview/TableOverview";
import VAutocompleteFilter from "../../components/filter/VAutocompleteFilter";
import { SET_FILTER } from "@/store/templates/table/mutation-types";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";

export default {
  name: "company-filter-logs",
  components: { VAutocompleteFilter, CompanyFilterLogTableRow, TableOverview },
  data: () => ({
    selectedFilters: []
  }),
  computed: {
    companyId() {
      return this.$store.state.company.model.id;
    }
  },
  watch: {
    selectedFilters: {
      handler(newVal) {
        this.$store.commit(`company/filterLogs/${SET_FILTER}`, {
          prop: "filter",
          value: newVal
        });
        this.$store.dispatch(`company/filterLogs/${DISPATCH_FETCH}`);
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.autocomplete {
  z-index: 15 !important;
}
</style>

<template>
  <v-container
    fluid
    v-bind="{ [`grid-list-${$vuetify.breakpoint.name}`]: true }"
    class="details-content-tab"
  >
    <v-textarea
      v-model="comment"
      label="Filter comment"
      :row-height="1"
      auto-grow
    />
    <v-progress-linear
      v-if="isLoading"
      :indeterminate="true"
      color="secondary"
      style="margin: 0px"
      height="2"
      value="15"
    />
    <div class="text-xs-center">
      <v-progress-linear
        v-if="isLoadingFilters"
        indeterminate
        height="2"
        color="primary"
      />
    </div>
    <div>
      <company-filters-tile
        v-bind:class="{
          loading: isLoadingFilters
        }"
        v-for="filterId in filterIds"
        :key="filterId"
        :company-id="companyId"
        :filter-id="filterId"
      />
    </div>
    <v-fab-transition>
      <v-btn
        v-show="focused"
        fab
        right
        bottom
        dark
        fixed
        color="pink"
        :style="{ bottom: '1rem' }"
        @click="goToCreate"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog v-model="internalDialog" content-class="company-filter-dialog">
      <router-view :company-id="companyId" />
    </v-dialog>
  </v-container>
</template>

<script type="text/babel">
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import { createNamespacedHelpers } from "vuex";
import { debounce } from "lodash";
const { mapGetters } = createNamespacedHelpers("company/filters");
import CompanyFiltersTile from "@/components/company/filters/CompanyFiltersTile";
import {
  IDS as GETTER_IDS,
  IS_LOADING as GETTER_IS_LOADING
} from "../../../store/templates/table/getter-types";

export default {
  name: "company-filters-view",
  components: { CompanyFiltersTile },
  data: () => ({
    focused: true,
    isLoading: false
  }),
  computed: {
    ...mapGetters(["offer"]),
    filterIds() {
      return this.$store.getters[`company/companyFilters/${GETTER_IDS}`];
    },
    isLoadingFilters() {
      return this.$store.getters[`company/companyFilters/${GETTER_IS_LOADING}`];
    },
    companyId() {
      return this.$store.getters["company/id"];
    },
    internalDialog: {
      get() {
        return this.dialog;
      },
      set() {
        this.$router.push({
          name: "management.companies.filters",
          params: { id: this.companyId }
        });
      }
    },
    comment: {
      get() {
        return this.$store.getters["company/filterComment"];
      },
      set(newValue) {
        this.$store.commit("company/setFilterComment", newValue);
        this.debouncedSave(newValue, this.saveFilterComment);
      }
    }
  },
  created() {
    this.getFilters();
  },
  methods: {
    async getFilters() {
      await this.$store.dispatch(`company/companyFilters/${DISPATCH_FETCH}`);
    },
    goToCreate() {
      this.$router.push({
        name: "company.filters.create",
        params: { id: this.companyId }
      });
    },
    debouncedSave: debounce((value, saveFunction) => {
      saveFunction(value);
    }, 2000),
    async saveFilterComment(value) {
      this.isLoading = true;
      await this.$store.dispatch("company/saveFilterComment", {
        comment: value
      });
      this.isLoading = false;
    }
  }
};
</script>
<style>
.v-dialog.company-filter-dialog {
  /*min-height: 60%;*/
  width: 60%;
}
.loading {
  opacity: 0.5;
}
</style>

<template>
  <v-list-tile-sub-title class="mt-2">
    Delivery method: {{ deliveryMethod.name }}:
    <v-chip
      v-for="(contactPerson, index) in contactPersons"
      :key="index"
      label
      small
    >
      {{ contactPerson.email }}
    </v-chip>
  </v-list-tile-sub-title>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "company-filters-delivery-method",
  props: {
    deliveryMethod: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    contactPersons() {
      return this.$store.getters[
        "company/companyFilters/deliveryMethodContactPersons"
      ](this.deliveryMethod.id);
    }
  }
};
</script>
